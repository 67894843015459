<template>
  <div>
    <h4 class="text-h5 table-title">{{ title }}</h4>
    <v-card elevation="0" outlined>
      <v-card-text>
        <table class="cart-description-table">
          <tbody>
          <tr v-if="showPaymentMethod">
            <td>Payment Method</td>
            <td> {{ paymentMethod }}</td>
          </tr>
          <tr>
            <td>Sub Total</td>
            <td>{{ subTotal | currency }}</td>
          </tr>
          <tr v-if="showTax">
            <td>Tax</td>
            <td>{{ tax | currency }}</td>
          </tr>
          <tr>
            <td>Shipping</td>
            <td>{{ shipping | currency }}</td>
          </tr>
          <tr>
            <td>Discount</td>
            <td>{{ discount | currency }}</td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <td>Grand Total</td>
            <td>{{ grandTotal | currency }}</td>
          </tr>
          </tfoot>
        </table>
        <slot></slot>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'OrderSummary',
  props: {
    title: {
      type: String,
      default: 'Order- Summary'
    },
    showPaymentMethod: {
      type: Boolean,
      default: false
    },
    showTax: {
      type: Boolean,
      default: false,
    },
    paymentMethod: {
      type: String,
      default: 'Cash on Delivery'
    },
    tax: {
      type: Number,
      default: 0
    },
    subTotal: {
      type: Number
    },
    shipping: {
      type: Number
    },
    discount: {
      type: Number
    },
    grandTotal: {
      type: Number
    },
  }
};
</script>

<style lang='scss' scoped>
.cart-description-table {
  width: 100%;
  display: table;

  td {
    font-size: 15px;
    text-transform: uppercase;
    text-align: right;
    width: 50%;
    padding-right: 8px;
    border-right: 1px solid #e3e3e3;

    & + td {
      text-align: left;
      padding-left: 8px;
      border-right-color: transparent;
    }
  }

  tbody {
    tr {
      &:last-child {
        td {
          padding-bottom: 10px;
          border-bottom: 1px solid #e3e3e3;
        }
      }
    }
  }

  tfoot {
    td {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 18px;
      font-weight: bold;
    }
  }
}

.table-title {
  display: flex;
  margin-bottom: 15px;
  flex-basis: 50%;
  align-items: center;

  &:after {
    flex: 1 1 auto;
    margin-left: 15px;
    content: "";
    display: inline-block;
    background-color: #e3e3e3;
    height: 2px;
  }
}
</style>
