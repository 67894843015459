<template>
  <v-simple-table class="cart-item-table" dense>
    <thead>
    <tr>
      <th>#</th>
      <th>image</th>
      <th>Product Details</th>
      <th>Unit Price</th>
      <th>Quantity</th>
      <th>Sub Total</th>
      <th>Action</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(item, index ) in cartItems" :key="'cart'+ index">
      <td>{{ index + 1 }}</td>
      <td class="product-image">
        <img v-if="productImage(item)" :alt="item.name" :src="productImage(item)" />
      </td>
      <td style="width: 45%">
        <h4 class="text-h6">{{ item.name }}</h4>
        <v-divider></v-divider>
        <div class="product-short-details" v-if="productAttribute(item).isNotEmpty()">
          <p
            v-for="(attribute, index) in productAttribute(item)"
            :key="'attr'+ index"
            class="product-attribute"
          >
            <span v-if="attribute.value">{{ `${attribute.name} ${attribute.value} ${attribute.value_type}` }}</span>
          </p>
        </div>
      </td>
      <td class="text-center">{{ item.price | currency }}</td>
      <td class="text-center">
        <v-btn
          :disabled="item.quantity <= 1"
          depressed x-small
          @click="updateTableData('minus', item)"
        >
          <v-icon> mdi-minus</v-icon>
        </v-btn>
        <p class="pb-0 pt-0 d-inline mr-4 ml-4">{{ item.quantity }}</p>
        <v-btn
          :disabled="item.quantity >= item.stock"
          depressed x-small
          @click="updateTableData('plus', item)"
        >
          <v-icon> mdi-plus</v-icon>
        </v-btn>
      </td>
      <td class="text-center">{{ (item.quantity * item.price) | currency }}</td>
      <td class="text-center">
        <v-icon @click="updateTableData('remove', item)">mdi-delete</v-icon>
      </td>
    </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import collect from 'collect.js';
import { storeHelper } from '@/utils/helper';
import { CART_ADD, CART_REMOVE, cartNameSpace } from '@/store/modules/cart/types';

export default {
  name: 'OrderDetailsTable',
  props: {
    cartItems: {
      type: Array
    }
  },
  methods: {
    updateTableData(type, item) {

      switch (type) {
        case 'plus':
          this.$store.dispatch(storeHelper(cartNameSpace, CART_ADD), item);
          break;

        case 'minus' :
          this.$store.dispatch(storeHelper(cartNameSpace, CART_REMOVE), { type: 'update', payload: item });
          break;

        case 'remove':
          this.$store.dispatch(storeHelper(cartNameSpace, CART_REMOVE), { type: 'remove', payload: item });
          break;
      }
    },

    productAttribute(item) {
      let collection = collect(item.attributes);
      return collection.unique('name');
    },

    productImage(item) {
      return item?.images[0]?.url;
    }
  }
};
</script>

<style lang='scss' scoped>
.cart-item-table {
  //display: table;
  //width: 100%;
  //border-spacing: 0;

  tbody {
    width: 100%;
  }

  thead {
    background-color: #e0e0e0;
    color: #232323;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;

    th, td {
      padding: 10px;
      border-spacing: 0;
    }
  }

  tr {
    td, th {
      vertical-align: middle;
      padding: 10px;
      border-bottom: 1px solid #e3e3e3;
      border-right: 1px solid #e3e3e3;
      border-left: 1px solid #e3e3e3;
    }

    &:nth-child(even) {
      //background-color: #e3e3e3;
    }
  }

  .product-image {
    width: 60px;
    height: 75px;

    img {
      max-width: 100%;
      width: 100%;
    }
  }

  .product-short-details {
    display: flex;
    flex-direction: row;
    column-gap: 10px;

    .product-attribute {
      position: relative;

      &:after {
        content: "|";
        margin-left: 10px;
      }

      &:last-of-type {
        &:after {
          content: "";
        }
      }
    }
  }
}
</style>
