<template>
  <v-container>
    <v-sheet>
      <v-container>
        <v-row>
          <v-col cols="12" md="9" lg="9">
            <v-card-text class="d-flex">
              <v-btn color="secondary" @click="backToProductPage">
                <v-icon class="mr-2">mdi-shopping-outline</v-icon>
                Continue Shopping
              </v-btn>
            </v-card-text>
            <v-stepper v-if="cartCount" v-model="orderDetailsForm" vertical elevation="0">
              <v-stepper-step :complete="status.order" step="1">Order Details</v-stepper-step>
              <v-stepper-content step="1">
                <v-row>
                  <v-col cols="12">
                    <OrderDetailsTable
                      :cart-items="cartItems"
                    />
                  </v-col>
                </v-row>

                <div class="d-flex mt-6">
                  <v-btn
                    :loading="stepperLoading"
                    color="primary mr-5"
                    @click="updateStepper('order')"
                  >
                    Continue
                  </v-btn>
                </div>
              </v-stepper-content>

              <v-stepper-step :complete="status.delivery" step="2">Delivery Details</v-stepper-step>
              <v-stepper-content step="2">
                <v-row>
                  <v-col cols="12">
                    <v-btn color="primary" @click="addressFormDialog = true"> {{ addresses.length ? 'Add more Address' : 'Add new Address' }}</v-btn>
                  </v-col>
                  <v-col v-for="(address, index) in addresses" :key="'address'+ index" cols="12" sm="6" md="4" lg="4" xl="4">
                    <div
                      :class="selected === address.id ?
                    'selected' : ''"
                    >
                      <address-card
                        :address="address"
                        :selectable="true"
                        @addressSelected="selectedForDelivery($event.type, $event.data, $event.value)"
                      >
                        <template slot-scope="slotProps">
                          <v-switch
                            v-model="addressSelection[index]"
                            :false-value="false"
                            :true-value="true"
                            :label="addressSelection[index] ? 'Your order will be delivered to this address' :'Select Address For Delivery'"
                            @change="selectedForDelivery('delivery', slotProps.data, $event, index)"
                          ></v-switch>
                        </template>
                      </address-card>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div>
                      <GmapMap :center="{lat: 51.509865, lng: -0.118092}" :zoom="10" style="width: 50%; min-width:350px; height: 250px;">
                        <GmapMarker v-for="(marker, index) in markers" :key="index" :position="marker.position" />
                        <GmapMarker
                          v-if="this.place"
                          :position="{ lat: this.place.geometry.location.lat(), lng: this.place.geometry.location.lng(),}"
                          label="★" />
                      </GmapMap>
                    </div>
                  </v-col>
                </v-row>
                <div class="d-flex mt-6 ">
                  <v-btn :disabled="!addresses.length" :loading="stepperLoading" color="primary mr-5"
                         @click="updateStepper('delivery')"> Continue
                  </v-btn>
                  <v-btn
                    color="primary"
                    outlined
                    :disabled="orderDetailsForm <= 1"
                    text
                    @click="orderDetailsForm = orderDetailsForm -1"
                  >
                    Back
                  </v-btn>
                </div>
              </v-stepper-content>

              <v-stepper-step :complete="status.payment" step="3">Payment information</v-stepper-step>
              <v-stepper-content step="3">
                <v-row>
                  <!--                <v-col cols="12" md="6" lg="2">-->
                  <!--                  <v-img-->
                  <!--                    class="cursor-pointer"-->
                  <!--                    :src="require('@/assets/images/paymentMethods/paypal.jpg')"-->
                  <!--                    alt="Paypal"-->
                  <!--                    max-width="200"-->
                  <!--                    v-ripple-->
                  <!--                    @click="handlePayment('paypal')"-->
                  <!--                  />-->
                  <!--                </v-col>-->
                  <v-col cols="12" md="6" lg="4" v-if="cardPaymentEnabled">
                    <SIcon
                      icon="mdi-credit-card"
                      text="Card Payment"
                      color="primary"
                      v-ripple
                      @click="handlePayment('card')"
                    />
                    <!--                  <v-btn-->
                    <!--                    color="primary"-->
                    <!--                    depressed-->
                    <!--                    v-ripple-->
                    <!--                    @click="handlePayment('card')"-->
                    <!--                    large-->
                    <!--                  >-->
                    <!--                    <v-icon left> mdi-credit-card </v-icon>-->
                    <!--                    Card-->
                    <!--                  </v-btn>-->
                    <!--                  <SIcon-->
                    <!--                    :size="120"-->
                    <!--                    icon="mdi-credit-card"-->
                    <!--                    v-ripple-->
                    <!--                    @click="handlePayment('card')"-->
                    <!--                  />-->
                    <!--                  <v-img-->
                    <!--                    class="cursor-pointer"-->
                    <!--                    :src="images.PAYMENT_METHOD.CARD"-->
                    <!--                    max-width="200"-->
                    <!--                    alt="Stripe"-->
                    <!--                    v-ripple-->
                    <!--                    @click="handlePayment('card')"-->
                    <!--                  />-->
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <SIcon
                      icon="mdi-cash-check"
                      v-ripple
                      text="Cash on Delivery"
                      color="primary"
                      @click="handlePayment('cash')"
                    />
                    <!--                  <v-img-->
                    <!--                    class="cursor-pointer"-->
                    <!--                    :src="images.PAYMENT_METHOD.CASH"-->
                    <!--                    max-width="200"-->
                    <!--                    alt="Cash"-->
                    <!--                    v-ripple-->
                    <!--                    @click="handlePayment('cash')"-->
                    <!--                  />-->
                  </v-col>
                </v-row>
                <div class="d-flex mt-6" style="padding-top: 60px">
                  <v-btn
                    :disabled="!paymentReceived" :loading="stepperLoading" color="primary mr-5"
                    @click="updateStepper('placed')"
                  >
                    Place Order
                  </v-btn>
                  <v-btn
                    outlined
                    :disabled="orderDetailsForm <= 1"
                    text
                    @click="orderDetailsForm = orderDetailsForm -1"
                    color="primary"
                    class="ml-3"
                  >
                    Back
                  </v-btn>
                </div>
              </v-stepper-content>

              <!--            <v-stepper-step :complete="status.placed" step="4">Place Order</v-stepper-step>-->
              <!--            <v-stepper-content step="4">-->
              <!--              <v-row>-->
              <!--                <v-col cols="12" md="6" lg="4">-->
              <!--                  <order-summary-->
              <!--                    :discount="discount"-->
              <!--                    :grandTotal="grandTotal"-->
              <!--                    :shipping="shipping"-->
              <!--                    :show-payment-method="true"-->
              <!--                    :subTotal="subTotal"-->
              <!--                    title="Order- Summery"-->
              <!--                  />-->
              <!--                </v-col>-->
              <!--              </v-row>-->
              <!--              <div class="d-flex mt-8">-->
              <!--                <v-btn :loading="stepperLoading" color="primary mr-5" @click="updateStepper('placed')"> Place Order-->
              <!--                </v-btn>-->
              <!--                <v-btn :disabled="orderDetailsForm <= 1" text @click="orderDetailsForm = orderDetailsForm -1"> Back-->
              <!--                </v-btn>-->
              <!--              </div>-->
              <!--            </v-stepper-content>-->
            </v-stepper>

            <div v-else>
              <v-card-title class="text-h3">Please Add Some item to Cart</v-card-title>
            </div>
          </v-col>
          <v-col cols="12" lg="3" class="d-none d-sm-block">
            <order-summary
              :discount="discount"
              :grandTotal="grandTotal"
              :shipping="shipping"
              :subTotal="subTotal"
              title="PAYMENT SUMMERY"
            >
              <!--                    <v-divider></v-divider>-->
              <!--                    <div class="coupon-container">-->
              <!--                      <p class="coupon-text">Enter Your Coupon Code, If you have one</p>-->
              <!--                      <v-text-field label="Coupon Code" outlined></v-text-field>-->
              <!--                      <v-btn color="secondary" width="100%"> Apply Coupon</v-btn>-->
              <!--                    </div>-->
            </order-summary>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <v-dialog v-model="addressFormDialog" persistent width="600">
      <user-address-form
        v-if="addressFormDialog"
        :mode="formMode"
        :user-data="selectedAddress"
        @updated="formUpdated()"
      />
    </v-dialog>

    <v-dialog v-model="deleteDialog" persistent width="400">
      <delete-confirmation @action="deleteAction($event)" />
    </v-dialog>

    <v-dialog v-model="cardPaymentModal" max-width="600" persistent>
      <pay-using-stripe
        v-if="cardPaymentModal"
        :amount="paymentAmount"
        :clientSecret="cardPaymentSecret"
        :customer-name="customerName"
        @cancelled="handleCancelPayment()"
        @payment-success="handleSuccessPayment"
      />
    </v-dialog>

  </v-container>
</template>

<script>
import { storeHelper } from '@/utils/helper';
import { IMAGES } from '@/utils/constants';
import {
  CART_COUNT,
  CART_DATA,
  CART_DISCOUNT_AMOUNT,
  CART_GRAND_TOTAL,
  CART_SAVED_ORDER,
  CART_SHIPPING_CHARGE,
  CART_SUBTOTAL,
  cartNameSpace
} from '@/store/modules/cart/types';
import { isStripeEnabled } from '@/config/app'
import { mapActions, mapGetters } from 'vuex';
import AddressCard from '@/components/User/AddressCard';
import UserAddressForm from '@/components/User/userAddressForm';
import DeleteConfirmation from '@/components/Global/DeleteConfirmation';
import { userAddressDelete } from '@/api/user';
import Toast from '@/utils/Toast';
import { getCardPaymentToken, placeOrder, saveOrder } from '@/api/order';
import OrderDetailsTable from '@/components/Cart/OrderDetailsTable';
import OrderSummary from '@/components/Cart/OrderSummary';
import PayUsingStripe from '@/components/Cart/PayUsingStripe';
import SIcon from '@/components/Utils/SIcon';

export default {
  name: 'Cart',
  components: {
    SIcon,
    PayUsingStripe,
    OrderSummary,
    OrderDetailsTable,
    DeleteConfirmation,
    UserAddressForm,
    AddressCard
  },
  data: () => ({
    cardPaymentModal: false,
    cardPaymentSecret: null,
    paymentAmount: 0,
    customerName: null,
    stepperLoading: false,
    images: IMAGES,
    cardPaymentEnabled: isStripeEnabled,

    orderDetailsForm: 1,
    addressSelection: {},
    deleteDialog: false,
    paymentReceived: false,
    payment_status: 'pending',
    payment_method: 'stripe',
    transaction_id: null,
    intentId: null,

    currentOrder: {},

    markers: [],
    place: null,
    panel: [0, 1],
    itemPrice: 1,
    selectedAddress: {},

    addressUpdating: false,
    addressFormDialog: false,

    isLoading: false,
    errors: {},

    mapOptions: {
      center: { lat: -27.47, lng: 153.025 },
      zoom: 12
    },

    status: {
      order: false,
      delivery: false,
      payment: false,
      placed: false
    },
  }),
  computed: {
    ...mapGetters({
      cartItems: storeHelper(cartNameSpace, CART_DATA),
      cartCount: storeHelper(cartNameSpace, CART_COUNT),
      subTotal: storeHelper(cartNameSpace, CART_SUBTOTAL),
      shipping: storeHelper(cartNameSpace, CART_SHIPPING_CHARGE),
      discount: storeHelper(cartNameSpace, CART_DISCOUNT_AMOUNT),
      grandTotal: storeHelper(cartNameSpace, CART_GRAND_TOTAL),
      userData: 'user/data',
      savedOrder: storeHelper(cartNameSpace, CART_SAVED_ORDER),
      currentUser: 'user/data'
    }),

    formMode() {
      return this.addressUpdating ? 'update' : 'create';
    },

    addresses() {
      return this.userData?.address?.length ? this.userData.address : [];
    },

    selected() {
      return this.selectedAddress?.id;
    },

    addressSelected() {
      return '';
    },
  },
  methods: {
    ...mapActions({
      storeOrder: storeHelper(cartNameSpace, CART_SAVED_ORDER)
    }),

    backToProductPage(){
      return this.$router.push({ name: 'Products' });
    },

    handleCancelPayment() {
      this.cardPaymentModal = false;
      this.paymentReceived = false;
    },

    async handleOrderPlacement() {

      if (!this.savedOrder?.id) {
        return false;
      }

      let data = {
        _method: 'PATCH',
        order_id: this.savedOrder.id,
        status: 'pending',
        payment_method: this.payment_method,
        payment_status: this.payment_status,
        transaction_id: this.transaction_id,
        pi: this.intentId,
      };

      try {
        const response = await placeOrder(data)
        this.currentOrder = response.data.data;
        return response;
      } catch (e) {
        this.errors = e?.errors;
        this.isLoading = false;
        Toast.notifyError('There is an Error in your Order Please try Later');
      }
    },

    async handleOrderSave() {

      let products = this.cartItems.map((item) => ({
        id: item.id,
        qty: item.quantity
      }));

      let errors = [];
      let data = {
        products: products,
        status: 'draft',
        payment_method: '',
        payment_status: 'pending',
        transaction_id: null,
        print_status: false,
        item_count: this.cartCount,
        subtotal: this.subTotal,
        delivery_charge: this.shipping,
        vat: null,
        coupon_code: null,
        coupon_amount: null,
        discount: this.discount,
        total: this.grandTotal,
        order_note: null,
        address: this.selectedAddress.id
      };

      try {
        return await saveOrder(data);
      } catch (e) {

        let match = /products/ig;

        if (e.errors) {
          for (const key in e.errors) {
            if (key.match(match)) {
              errors.push(e.errors[key][0]);
            }
          }
        }

        this.errors = errors;

        if (errors.length) {
          errors.map((error) => {
            Toast.notifyError(error);
          });
        }
      }
    },

    async handlePayment(paymentType) {

      this.customerName = this.currentUser?.full_name;

      switch (paymentType) {

        case 'cash' :
          this.payment_method = 'cash';
          this.paymentReceived = true;
          break;

        case 'card' : {
          this.payment_method = 'stripe';
          this.paymentAmount = this.savedOrder?.total || 0;
          await this.handleCardPayment();
        }
          break;
      }
      // await this.updateStepper('payment');
      // await this.updateStepper('placed');

    },

    async handleCardPayment() {
      let response = await getCardPaymentToken();
      this.cardPaymentSecret = response?.data?.data?.token;
      this.cardPaymentModal = true;
    },

    handleSuccessPayment(event) {
      Toast.notifySuccess('Payment Successful');

      this.transaction_id = event?.paymentIntent?.id;
      this.intentId = event?.paymentIntent?.client_secret;
      this.payment_status = 'paid';
      this.payment_method = 'stripe';
      this.paymentReceived = true;
      this.cardPaymentModal = false;

      // this.updateStepper('payment');
      this.updateStepper('placed');
    },

    async deleteAction(type) {

      if (type === 'confirmed') {
        try {
          let response = await userAddressDelete(this.selectedAddress.id);
          await Toast.info(response.data.message);
          await this.$store.dispatch('app/fetchLoggedInUser');
          this.selectAddress();
        } catch (e) {
          Toast.error(e.message);
        }
      }

      this.formUpdated();
    },

    formUpdated() {
      this.addressFormDialog = false;
      this.selectedAddress = {};
      this.addressUpdating = false;
      this.deleteDialog = false;
      this.selectAddress();
    },

    selectedForDelivery(type, data, value, index) {

      this.selectedAddress = data;

      if (type === 'delivery') {

        if (!value) {
          this.selectedAddress = {};
          return;
        }

        let previousSelection = this.addressSelection;

        for (let key in previousSelection) {
          if (key !== index) {
            previousSelection[key] = false;
          }
        }

        previousSelection[index] = value;
        this.addressSelection = previousSelection;
      }

      if (type === 'update') {
        this.addressUpdating = true;
        this.addressFormDialog = true;
      }

      if (type === 'delete') {
        this.deleteDialog = true;
      }
    },

    setDescription(description) {
      this.description = description;
    },

    async updateStepper(type) {

      this.isLoading = true;
      let errorMsg = 'Something went Wrong. Please Try Later';
      let validated = false;

      if (type === 'order') {

        if (this.cartItems) {
          validated = true;
        }

        errorMsg = 'Please Add Some items into cart to continue';
      }

      if (type === 'delivery') {
        let currentAddress = this.selectedAddress?.id;

        if (currentAddress) {
          validated = true;
        }

        this.stepperLoading = true;
        let saveOrderResponse = await this.handleOrderSave();
        this.stepperLoading = false;

        if (!saveOrderResponse?.data?.status) {

          errorMsg = 'There is and error in your order';
          validated = false;
          this.orderDetailsForm = 1;
          return;

        }

        await this.storeOrder(saveOrderResponse?.data?.data);
        errorMsg = 'Please Select Or add An Address To Continue';
      }

      if (type === 'payment') {

        if (this.paymentReceived) {
          validated = true;
        }

        errorMsg = 'Please Confirm Your Payment To Continue';
      }

      if (type === 'placed') {
        this.stepperLoading = true;
        let response = await this.handleOrderPlacement();
        this.stepperLoading = false;
        this.status[type] = false;

        if (response.status) {
          return await this.$router.push({ name: 'OrderSuccess', params: { order: 'success', orderData: this.currentOrder } });
        }
      }

      if (validated) {
        this.isLoading = false;
        this.status[type] = true;
        this.orderDetailsForm++;
        return;
      }

      Toast.notifyError(errorMsg, '', async () => {
        this.isLoading = false;
      });
    },

    selectAddress() {

      if (!this.addresses.length) {
        return;
      }

      if (this.addresses.length === 1) {
        this.selectedAddress = this.addresses[0];
        this.addressSelection[0] = true;
        return;
      }

      this.addresses.map((address, index) => {
        if (address.is_default) {
          this.selectedAddress = address;
          this.addressSelection[index] = true;
        }
      });
    }
  },
  mounted() {
    this.selectAddress();
  }
};
</script>

<style lang="scss">
.selected {
  border: 2px solid #1DA1F2;
}

.coupon-container {
  padding: 10px;
  margin-top: 20px;
}
</style>
