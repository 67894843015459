<template>
  <v-card
    :class="`${color} ${pointer ? 'cursor-pointer' : ''}`"
    @click="handleClick"
  >
    <v-card-text>
      <div class="d-flex align-center flex-column">
        <v-avatar size="120" :color="color">
          <v-icon size="120" color="white">{{ icon }}</v-icon>
        </v-avatar>

        <v-card-title class="pa-0">
          <div class="d-flex flex-column">
            <p class="ma-1 mt-0 text-h6 white--text">{{ text }}</p>
          </div>
        </v-card-title>

      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'SIcon',
  props: {

    icon: {
      type: String,
    },

    text: {
      type: String,
    },

    color: {
      type: String,
      default: 'secondary'
    },
    pointer: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleClick(){
      this.$emit('click')
    },
  }
};
</script>

<style scoped>
.cursor-pointer{
  cursor: pointer;
}
</style>
